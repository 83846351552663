import { Navigate, Outlet } from '@remix-run/react';

import { useToken } from '../../utils/token';
import { buildSearchParamsWithRedirectToAsString } from './hooks';
import { type LoginType } from './types';

export function RequireToken(props: {
  redirectPath?: string;
  loginType?: LoginType;
  children?: JSX.Element;
}): JSX.Element {
  const [token] = useToken(true);
  const pathname = props.redirectPath || '/login';
  return token ? (
    props.children ? (
      props.children
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate
      replace
      to={{
        pathname,
        search: buildSearchParamsWithRedirectToAsString(window.location.href, {
          'login-type': props.loginType,
        }),
      }}
    />
  );
}
